/* eslint-disable vue/experimental-script-setup-vars */
<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark text-white font-bold w-full fixed md:static"
		>
			<span class="ml-2">Cotización</span>

			<button v-if="cotizacionPendienteVendedor" class="text-white font-bold">
				Pendientes por Vendedor
			</button>

			<div class="flex items-center justify-center">
				<router-link to="/Cotizaciones"
					><button
						@click="$emit('click')"
						class="py-2 px-4 inline-flex items-center focus:outline-none font-bold"
					>
						<svg
							version="1.1"
							id="Capa_1"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							viewBox="0 0 368.022 368.022"
							style="enable-background: new 0 0 368.022 368.022"
							class="h-6 w-6"
							xml:space="preserve"
						>
							<path
								style="fill: #d7443e"
								d="M314.101,54.133c71.79,71.847,71.744,188.287-0.103,260.077s-188.287,71.744-260.077-0.103 c-71.75-71.806-71.75-188.167,0-259.974c71.381-71.847,187.49-72.224,259.337-0.843C313.54,53.57,313.821,53.851,314.101,54.133z"
							/>
							<polygon
								style="fill: #ffffff"
								points="275.439,124.663 215.88,184.223 275.439,243.782 243.57,275.651 184.011,216.092 124.452,275.651 92.582,243.782 152.142,184.223 92.582,124.663 124.452,92.794 184.011,152.353 243.57,92.794"
							/>
						</svg>
						<span class="underline ml-2">Cancelar cotización</span>
					</button></router-link
				>
			</div>
		</div>
		<!--==============================Campo superiores=====================================================-->
		<div class="md:flex items-center justify-between mt-20 md:mt-4">
			<div class="text-center md:w-1/3 ml-2 md:ml-0">
				<Campo2 campoTitulo="Vendedor">
					<vSelect
						class="w-64 border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center focus:outline-none"
						:options="opcionesVendedor"
						v-model="vendedorAsignado"
						@input="vendedor => asignarVendedor(vendedor)"
					></vSelect>
				</Campo2>

				<Campo2 class="mt-3" campoTitulo="Teléfono">
					<input
						type="text"
						disabled
						class="w-64 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
						v-model="numeroTlf"
					/>
				</Campo2>

				<Campo2 class="mt-3" campoTitulo="Nombre">
					<input
						type="text"
						disabled
						class="w-64 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
						v-model="nombreCliente"
					/>
				</Campo2>
			</div>
			<div class="text-center md:w-1/3 ml-2 md:ml-0">
				<div class="flex md:justify-center md:mr-10">
					<Campo2 class="mt-3" campoTitulo="Tasa del día $">
						<input
							type="text"
							disabled
							class="w-64 -mr-3 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							:value="formato(tasadia)"
						/>
					</Campo2>
				</div>
				<div class="flex justify-start">
					<input
						class="mx-3 mt-3"
						type="radio"
						name="DescuentoAumento"
						id="descuento"
						value="Descuento"
						v-model="tipoPorcentaje"
					/>
					<Campo2 class="mt-3" campoTitulo="% Descuento">
						<input
							type="text"
							class="w-64 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							v-model="descuento"
						/>
					</Campo2>
				</div>
				<div class="flex justify-start">
					<input
						class="mx-3 mt-3"
						type="radio"
						name="DescuentoAumento"
						id="aumento"
						value="Aumento"
						v-model="tipoPorcentaje"
					/>
					<Campo2 class="mt-3 ml-3" campoTitulo="% Aumento">
						<input
							type="text"
							class="w-64 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							v-model="aumento"
						/>
					</Campo2>
				</div>
			</div>
			<div class="text-center md:w-1/3">
				<div class="flex justify-start items-center ml-10">
					<span class="font-bold mr-2">Tipo de pago</span>
					<select
						name="tipoPago"
						id="tipoPago"
						v-model="prePost"
						class="w-32 ml-16 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
						@change="cobradoPrepago()"
					>
						<option value="0">Prepago</option>
						<option value="1">Postpago</option>
					</select>
				</div>
				<div class="flex justify-start items-center ml-10 mt-6">
					<span class="font-bold mr-2">Fecha de entrega</span>
					<input
						class="w-36 ml-8 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
						type="date"
						v-model="fechaEntrega"
					/>
				</div>
				<div class="flex justify-start items-center ml-10 mt-6">
					<span class="font-bold mr-10">Tiempo Estimado</span>
					<input
						class="w-36 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
						type="time"
						v-model="tiempoEstimado"
					/>
				</div>
			</div>
		</div>
		<div class="md:flex justify-end align-middle mt-6 mr-24">
			<div>
				<input
					class="mx-3"
					type="radio"
					name="TipoPdf"
					id="aumento"
					value="Bolivares"
					v-model="tipoPDF"
				/>
				<span> Mostrar monto en Bs al cliente</span>
			</div>
			<div>
				<input
					class="mx-3"
					type="radio"
					name="TipoPdf"
					id="aumento"
					value="Dolares"
					v-model="tipoPDF"
				/>
				<span> Mostrar monto en $ al cliente</span>
			</div>
			<div>
				<input
					class="mx-3"
					type="radio"
					name="TipoPdf"
					id="aumento"
					value="DolaresBolivares"
					v-model="tipoPDF"
				/>
				<span> Mostrar monto en Bs y $ al cliente</span>
			</div>
			<!-- <input class="h-4 w-4 mr-1" type="checkbox" v-model="mostrarDolar" />
      <span> Mostrar monto en $ al cliente</span> -->
		</div>
		<!--===============================Tabla ingreso de datos==================================================-->
		<div class="flex justify-center mx-10">
			<table id="producto" class="table-auto mt-4">
				<thead>
					<tr>
						<th class="border-2 px-4 py-2">Rubro</th>
						<th class="border-2 px-4 py-2">Cant.</th>
						<th class="border-2 px-4 py-2">Producto</th>
						<th class="border-2 px-4 py-2">Und.</th>
						<th class="border-2 px-4 py-2 w-28">P. Unit. Bs</th>
						<th class="border-2 px-4 py-2 w-30">P. Unit. $</th>
						<th class="border-2 px-4 py-2 w-28">Total Bs</th>
						<th class="border-2 px-4 py-2 w-28">Total $</th>
						<!-- <th class="border-2 px-4 py-2">%</th> -->
						<th class="border-2 px-4 py-2">Comentario</th>
						<th class="border-2 px-4 py-2">Vendedor</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(cotizacion, k) in productosCotizacion" :key="k">
						<td class="border-2 px-4 py-4">
							<vSelect
								class="w-32 bg-white hover:bg-gray-200 text-black items-center focus:outline-none"
								:options="opcionesRubro"
								v-model="cotizacion.Rubro"
								@input="opcionesProducto(cotizacion.Rubro)"
							></vSelect>
						</td>
						<td class="border-2 px-4 py-4">
							<input
								class="w-16 focus:outline-none px-auto"
								type="number"
								step="0.01"
								v-model="cotizacion.Cantidad"
								@keyup="montoCobrarBsUsd(cotizacion)"
							/>
						</td>
						<td class="border-2 px-4 py-4">
							<vSelect
								@change="productoId"
								class="w-64 bg-white hover:bg-gray-200 text-black items-center focus:outline-none"
								:options="nuevoProducto"
								v-model="cotizacion.Producto"
								:key="selectRefresh"
								@input="precioPorproducto(cotizacion.Producto)"
								>{{ llenarOpcionesProductos }}
							</vSelect>
						</td>

						<td class="border-2 px-4 py-4">
							{{ cotizacion.Unidad }}
						</td>

						<td class="border-2 px-4 py-1">
							{{ formato(cotizacion.PrecioUnitarioBs) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ formato(cotizacion.PrecioUnitarioD) }}
						</td>
						<td class="border-2 px-4 py-4 text-center">
							<div
								class="text-center"
								v-if="
									cotizacion.PrecioUnitarioBs != '' && cotizacion.cantidad != ''
								"
							>
								{{ formato(cotizacion.MontoCobrarBs) }}
							</div>
							<div class="text-center" v-else>
								{{ (cotizacion.MontoCobrarBs = 0) }}
							</div>
						</td>
						<td class="border-2 px-4 py-4 text-center">
							<div
								class="text-center"
								v-if="
									cotizacion.PrecioUnitarioD != '' && cotizacion.cantidad != ''
								"
							>
								{{ formato(cotizacion.MontoCobrarD) }}
							</div>
							<div class="text-center" v-else>
								{{ (cotizacion.MontoCobrarD = 0) }}
							</div>
						</td>
						<!-- <td class="border-2 px-4 py-4">
              {{ cotizacion.Descuento }}
            </td> -->
						<td class="border-2">
							<textarea
								class="w-full focus:outline-none px-auto"
								v-model="cotizacion.Comentario"
							/>
						</td>
						<td class="border-2 px-10">
							<input
								class="h-4 w-4"
								type="checkbox"
								id="asignar"
								name="vendedor"
								@keypress.enter="consultarVendedor = !consultarVendedor"
								v-model="consultarVendedor"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="flex justify-center pt-10" v-if="showMessage">
			<p>{{ message }}</p>
		</div>

		<div
			v-if="mostrarAnadirProducto"
			class="flex items-center justify-center w-full my-10"
		>
			<Botons
				@click="addNewRow"
				class="underline mr-10"
				botonsText="Añadir producto"
			>
				<anadir class="h-6 w-6 mr-2 fill-current text-botonT" />
			</Botons>
		</div>
		<div
			v-if="notificarVendedor"
			class="flex items-center justify-center w-full my-10"
		>
			<Botons
				@click="notificarVendedorMutation"
				class="underline mr-10"
				botonsText="Notificar Vendedor"
			>
				<anadir class="h-6 w-6 mr-2 fill-current text-botonT" />
			</Botons>
		</div>

		<!--==============================Tabla de cotizacion=====================================================-->
		<div class="flex justify-center mx-10">
			<table id="cotizarr" class="table-auto mt-4">
				<thead>
					<tr>
						<th></th>
						<th class="border-2 px-4 py-2">Cant.</th>
						<th class="border-2 px-4 py-2">Und.</th>
						<th class="border-2 px-4 py-2">Rubro</th>
						<th class="border-2 px-4 py-2">Producto</th>
						<th class="border-2 px-4 py-2">P. Unit. Bs</th>
						<th class="border-2 px-4 py-2">P. Unit.$</th>
						<th class="border-2 px-4 py-2">Total Bs</th>
						<th class="border-2 px-4 py-2">Total $</th>
						<!-- <th class="border-2 px-4 py-2">%</th> -->
						<th class="border-2 px-4 py-2">Comentario</th>
						<th class="border-2 px-4 py-2">Vendedor</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(cotizaciont, k) in productosCotizacionT" :key="k">
						<td scope="row">
							<svg
								@click="deleteRow(k, cotizaciont)"
								class="hover:opacity-100 cursor-pointer fill-current text-red-500 opacity-75"
								width="26"
								height="26"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g id="delete_sweep_24px">
									<path
										id="icon/content/delete_sweep_24px"
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M6 4H10L11 5H14V7H2V5H5L6 4ZM5 20C3.89999 20 3 19.1 3 18V8H13V18C13 19.1 12.1 20 11 20H5ZM22 8H15V10H22V8ZM19 16H15V18H19V16ZM15 12H21V14H15V12ZM5 10H11V18H5V10Z"
									/>
								</g>
							</svg>
						</td>
						<td class="border-2 px-4 py-4">
							{{ cotizaciont.Cantidad }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ firstLetterUpperCase(cotizaciont.Unidad) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ firstLetterUpperCase(cotizaciont.Rubro) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ firstLetterUpperCase(cotizaciont.Producto) }}
						</td>
						<td class="border-2 px-4 py-1">
							{{ formato(cotizaciont.PrecioUnitarioBs) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ formato(cotizaciont.PrecioUnitarioD) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ formato(cotizaciont.MontoCobrarBs) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ formato(cotizaciont.MontoCobrarD) }}
						</td>
						<!-- <td class="border-2 px-4 py-4">
              {{ formato(cotizaciont.Descuento) }}
            </td> -->
						<td class="border-2 px-4 py-4">
							<textarea
								class="w-full focus:outline-none px-auto"
								v-model="cotizaciont.Comentario"
							/>
						</td>
						<td class="border-2 px-10">
							<input
								v-model="cotizaciont.consultarVendedor"
								class="h-4 w-4"
								type="checkbox"
								id="asignar"
								name="vendedor"
							/>
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">Exento</td>
						<td class="text-center border-2 px-4 py-4">
							{{
								formato(
									parseFloat(subtotalbs) -
										parseFloat(ivabs) / ($store.state.ivaGeneral / 100)
								)
							}}
						</td>
						<td class="text-center border-2 px-4 py-4">
							{{
								formato(
									parseFloat(subtotalusd) -
										parseFloat(ivad) / ($store.state.ivaGeneral / 100)
								)
							}}
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">Subtotal</td>
						<td class="text-center border-2 px-4 py-4">
							{{ formato(parseFloat(ivabs / ($store.state.ivaGeneral / 100))) }}
						</td>
						<td class="text-center border-2 px-4 py-4">
							{{ formato(parseFloat(ivad / ($store.state.ivaGeneral / 100))) }}
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">
							{{ "% de " + tipoPorcentaje }}
						</td>
						<td
							colspan="2"
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							<input
								class="text-center px-4 py-4"
								type="text"
								name=""
								id=""
								v-model="aumento"
							/>
						</td>
						<td
							colspan="2"
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							<input
								class="text-center px-4 py-4"
								type="text"
								name=""
								id=""
								v-model="descuento"
							/>
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">Base Imponible sin %</td>
						<td class="text-center border-2 px-4 py-4">
							{{ formato(subtotalbs) }}
						</td>
						<td class="text-center border-2 px-4 py-4">
							{{ formato(subtotalusd) }}
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">Base Imponible</td>
						<td
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(subtotalbs / (1 - parseFloat(aumento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(subtotalusd / (1 - parseFloat(aumento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(subtotalbs * (1 - parseFloat(descuento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(subtotalusd * (1 - parseFloat(descuento) / 100)) }}
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">IVA</td>
						<td
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(this.ivabs / (1 - parseFloat(aumento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(this.ivad / (1 - parseFloat(aumento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(this.ivabs * (1 - parseFloat(descuento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(this.ivad * (1 - parseFloat(descuento) / 100)) }}
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">Total</td>
						<td
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(totalbs / (1 - parseFloat(aumento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(totalusd / (1 - parseFloat(aumento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(totalbs * (1 - parseFloat(descuento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(totalusd * (1 - parseFloat(descuento) / 100)) }}
						</td>
					</tr>
					<!-- <tr v-if="show">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-center border-2 px-4 py-4">Utilidad Aproximada</td>
            <td
              v-if="tipoPorcentaje === 'Aumento'"
              class="text-center border-2 px-4 py-4"
            >
              {{
                formato(
                  subtotalbs * (1 + parseFloat(aumento) / 100) -
                    utilidadAproximadaBs
                )
              }}
            </td>
            <td
              v-if="tipoPorcentaje === 'Aumento'"
              class="text-center border-2 px-4 py-4"
            >
              {{
                formato(
                  subtotalusd * (1 + parseFloat(aumento) / 100) -
                    utilidadAproximadaUSD
                )
              }}
            </td>
            <td
              v-if="tipoPorcentaje === 'Descuento'"
              class="text-center border-2 px-4 py-4"
            >
              {{
                formato(
                  subtotalbs * (1 - parseFloat(descuento) / 100) -
                    utilidadAproximadaBs
                )
              }}
            </td>
            <td
              v-if="tipoPorcentaje === 'Descuento'"
              class="text-center border-2 px-4 py-4"
            >
              {{
                formato(
                  subtotalusd * (1 - parseFloat(descuento) / 100) -
                    utilidadAproximadaUSD
                )
              }}
            </td>
          </tr> -->
				</tbody>
			</table>
		</div>
		<div class="flex items-center justify-center w-full my-10">
			<Botonp
				class="underline"
				botonpText="Calcular Total"
				@click="
					calcularTotalusd();
					calcularTotalbs();
					idVendedor();
					utilidadAproximada();
					botones = true;
					show = true;
				"
			/>
		</div>
		<div v-if="botones != false" class="flex items-center justify-center mb-10">
			<div>
				<div class="flex justify-between mb-8">
					<div v-if="mostrarBotonActualizar">
						<Botonp
							v-if="mostrarPedido"
							botonpText="Actualizar Pedido"
							class="mx-10"
							@click="actualizarPedidoaCompras()"
						/>
					</div>
					<Botons
						botonsText="Imprimir PDF"
						class="mx-10"
						@click="generarPDF()"
					/>
				</div>

				<div class="flex justify-center">
					<Botonp
						v-if="mostrarPedido"
						botonpText="Enviar pedido"
						class="mx-10"
						@click="enviarPedidoaCompras()"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Botons from "@/components/botonSecundario";
import Botonp from "@/components/botonPrimario";
import anadir from "@/components/añadir";
import Campo2 from "@/components/campoCotizar";
import vSelect from "vue-select";
import "@/assets/styles/vue-select.css";
import { formato, firstLetterUpperCase } from "../functions.js";
import pdfMixin from "@/mixins/pdfMixin.js";
import cotizarMixin from "@/mixins/cotizarMixin.js";
import rubroTipoCambio from "@/mixins/rubroTipoCambio.js";

export default {
	name: "Cotizacion",
	components: {
		Botons,
		Botonp,
		anadir,
		vSelect,
		Campo2
	},
	data: function() {
		return {
			mostrarBotonActualizar: true,
			notificarVendedor: false,
			mostrarAnadirProducto: true,
			cobrado: "",
			cotizacionPendienteVendedor: false,
			arrayEliminar: [],
			tipoPorcentaje: "Aumento",
			tipoPDF: "Dolares",
			mostrarPedido: true,
			showMessage: false,
			message: "",
			nuevoProducto: [],
			consultarVendedor: false,
			llenarOpcionesProductos: [],
			rubroCompletado: [],
			selectRefresh: true,
			CotizacionId: [],
			aumento: 0,
			descuento: 0,
			productoEliminado: false,
			botones: false,
			tasadia: "",
			mostrarDolar: false,
			show: false,
			tiempoEstimado: "",
			vendedoress: [],
			usuarios: [],
			numeroCliente: [],
			numeroTlf: "",
			vendedorId: "",
			fechaEntrega: "",
			productoId: "",
			prePost: "",
			totalbs: 0,
			subtotalbs: 0,
			totalusd: 0,
			subtotalusd: 0,
			ivabs: 0,
			ivad: 0,
			utilidades: 0,
			iva: 16,
			rubrosTotal: [],
			productosCotizacionT: [],
			productosCotizacion: [
				{
					Cantidad: "",
					Unidad: "",
					Rubro: "",
					Producto: "",
					PrecioUnitarioBs: "",
					PrecioUnitarioD: "",
					MontoCobrarBs: "",
					MontoCobrarD: "",
					Comentario: "NA",
					Vendedor: "",
					Descuento: "",
					ivabs: "",
					ivad: "",
					tasa_proveedores: []
				}
			],
			compraId: [],
			numeroCotizacion: "",
			user_Receptor: "",
			notificacionVendedorWS: false,
			utilidadAproximadaUSD: 0,
			utilidadAproximadaBs: 0,
			vendedorAsignado: "",
			nombreCliente: "",
			vendedorModificado: false,
			cotizacionIdBroadcasting: 0
		};
	},
	mixins: [pdfMixin, cotizarMixin, rubroTipoCambio],
	/////////////////////////////////created/////////////////////////////////////////////////////////////////////
	async created() {
		await this.listaVendedoresQuery();
		await this.usuariosQuery();
		await this.rubroTotalQuery();
		await this.tipoCambioQuery();
		await this.cotizacionIdQuery();
	},
	//////////////////////////////////methods///////////////////////////////////////////////////////////////////
	methods: {
		formato,
		firstLetterUpperCase,
		asignarVendedor(nuevoVendedor) {
			this.vendedorModificado = true;
			this.productosCotizacion[0].Vendedor = nuevoVendedor;
		},
		async cotizacionIdQuery() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/cotizacionId.gql"),
					variables: {
						cotizacion_id: this.$route.params.id
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.CotizacionId = data.data.CotizacionId;
					this.numeroCotizacion = this.$route.params.id;
					this.productosCotizacionT = this.cargarDataTabla;
					this.vendedorAsignado = this.CotizacionId[0].vendedores[0].user.name;
					this.productosCotizacion[0].Vendedor = this.CotizacionId[0].vendedores[0].user.name;
					this.numeroTlf = this.CotizacionId[0].user[0].numero_telefono_movil;
					this.nombreCliente = this.CotizacionId[0].user[0].name;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		},

		//Actualiza SOLO la cotización, no lo envia a orden de compra
		async actualizarPedidoaCompras() {
			let arrayProductosActualizados = [];
			let comprasID = 1;
			let porcentaje = 0;
			let porcentaje1 = 0;
			let cotizacion = "0";
			this.mostrarBotonActualizar = false;
			this.idVendedor();

			if (this.tipoPorcentaje === "Aumento") {
				porcentaje1 = parseFloat(this.aumento);
			} else {
				porcentaje1 = -1 * parseFloat(this.descuento);
			}

			if (this.tipoPorcentaje === "Aumento") {
				porcentaje = 1 + parseFloat(this.aumento) / 100;
			} else {
				porcentaje = 1 - parseFloat(this.descuento) / 100;
			}
			if (this.compraId.id) {
				comprasID = this.compraId.id;
				cotizacion = "1";
			}

			for (let i = 0; i < this.productosCotizacionT.length; i++) {
				if (this.productosCotizacionT[i].consultarVendedor) {
					this.notificacionVendedorWS = true;
				}
				arrayProductosActualizados.push({
					id: parseInt(this.productosCotizacionT[i].id),
					user_id: parseInt(this.CotizacionId[0].user_id),
					vendedor_id: parseInt(this.vendedorId),
					compras_id: parseInt(comprasID),
					precios_id: parseInt(this.productosCotizacionT[i].Idprecio),
					cantidad: parseFloat(this.productosCotizacionT[i].Cantidad),
					total_usd: parseFloat(this.productosCotizacionT[i].MontoCobrarD),
					estado: 0,
					comentario: this.productosCotizacionT[i].Comentario,
					comprado: 0,
					cotizacion: parseInt(cotizacion),
					cotizacion_id: parseInt(this.$route.params.id),
					porcentaje: parseFloat(porcentaje1),
					notificar: this.intToBoolean(
						this.productosCotizacionT[i].consultarVendedor
					),
					tasa_proveedores: parseFloat(
						this.productosCotizacionT[i].tasa_proveedores
					)
				});
			}
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: require("@/graphql/mutations/actualizarPedido.gql"),
					variables: {
						id: this.arrayEliminar,
						data: arrayProductosActualizados
					}
				})
				.then(async data => {
					this.mostrarBotonActualizar = true;
					if (this.compraId.id) {
						alert("Orden de compra generada con exito");
					} else {
						await this.pesosVendedor(this.$route.params.id);
						await this.cotizacionIdQuery();
						alert("Cotización Actualizada");
						/* for (let i = 0; i < data.data.actualizarPedido.length; i++) {
							this.productosCotizacionT[i].id =
								data.data.actualizarPedido[i].id;
						} */
					}
					if (this.notificacionVendedorWS) {
						this.cotizacionIdBroadcasting = parseInt(this.$route.params.id);
						this.notificarVendedorBroadcasting();
						this.notificacionVendedorWS = false;
					}
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					this.message = error;
					this.showMessage = true;
					this.mostrarBotonActualizar = true;
				});
		},
		cobradoPrepago() {
			if (this.prePost == 0) {
				this.cobrado = 1;
			} else {
				this.cobrado = 0;
			}
		},

		intToBoolean(number) {
			if (number === 0 || !number) {
				return false;
			}
			return true;
		},

		deleteRow(index, Cotizacion) {
			const idx = this.productosCotizacionT.indexOf(Cotizacion);
			if (idx > -1) {
				if (this.productosCotizacionT[index].id) {
					this.arrayEliminar.push(this.productosCotizacionT[index].id);
				}
				this.productosCotizacionT.splice(idx, 1);
			}
		},

		eliminarProducto(index) {
			if (this.CotizacionId[index].id) {
				this.$apollo
					.mutate({
						mutation: require("@/graphql/mutations/eliminarCotizacion.gql"),
						variables: {
							id: this.CotizacionId[index].id
						}
					})
					.catch(error => {
						console.log(error);
					});
				this.productoEliminado = true;
			}
		},
		//////////////////////////////cotizacion->compras//////////////////////////////////////////////////
		async enviarPedidoaCompras() {
			let cotizacionId = [];
			let porcentaje = 0;
			let porcentaje1 = 0;
			let arrayProductosActualizados = [];
			let nuevaListaProductos = [];
			this.idVendedor();
			if (this.tipoPorcentaje === "Aumento") {
				porcentaje1 = parseFloat(this.aumento);
			} else {
				porcentaje1 = -1 * parseFloat(this.descuento);
			}

			if (this.tipoPorcentaje === "Aumento") {
				porcentaje = 1 / (1 - parseFloat(this.aumento) / 100);
			} else {
				porcentaje = 1 - parseFloat(this.descuento) / 100;
			}

			if (
				this.prePost != "" &&
				this.fechaEntrega != "" &&
				this.tiempoEstimado != ""
			) {
				//Verificacion para fecha de entrega mayor o igual a la fecha actual
				let todayRaw = new Date();
				let today = new Date(
					String(todayRaw.getFullYear()) +
						"/" +
						String(todayRaw.getMonth() + 1).padStart(2, "0") +
						"/" +
						String(todayRaw.getDate()).padStart(2, "0")
				);
				let fechaPedido = new Date(this.fechaEntrega.replaceAll("-", "/"));
				if (fechaPedido >= today) {
					this.mostrarPedido = false;

					for (
						let index = 0;
						index < this.productosCotizacionT.length;
						index++
					) {
						nuevaListaProductos.push({
							Cantidad: this.productosCotizacionT[index].Cantidad,
							Idprecio: this.productosCotizacionT[index].Idprecio,
							PrecioUnitarioD:
								parseFloat(this.productosCotizacionT[index].PrecioUnitarioD) *
								porcentaje,
							Producto: this.productosCotizacionT[index].Producto,
							ivad: this.productosCotizacionT[index].ivad * porcentaje,
							Unidad: this.productosCotizacionT[index].Unidad
						});
					}
					//Montar Orden de compra
					this.$store.state.isLoading = true;
					await this.$apollo
						.mutate({
							mutation: require("@/graphql/mutations/montarCotizacion.gql"),
							variables: {
								vendedor_id: this.vendedorId,
								user_id: this.CotizacionId[0].user_id,
								estatus: "asignado",
								fecha_entrega: this.fechaEntrega,
								lista_productos: JSON.stringify(nuevaListaProductos),
								total_factura:
									parseFloat(this.totalusd) * parseFloat(porcentaje),
								forma_pago: this.prePost,
								tiempo_estimado: this.tiempoEstimado,
								tasa_dia: parseFloat(this.tasadia),
								comision_vendedor: parseFloat(
									this.CotizacionId[0].vendedores[0].comision
								),
								pago_vendedor: 0,
								utilidades: this.utilidades,
								cobrado: this.cobrado
							}
						})
						.then(data => {
							this.compraId = data.data.montarCotizacion;

							//Actualizar pedido
							this.actualizarPedidoaCompras();

							//Notificar nueva Orden de compra

							this.notificarNuevaOrdenCompraBroadcast();
							this.$store.state.isLoading = false;
						})
						.catch(error => {
							this.message = error;
							this.showMessage = true;
							this.$store.state.isLoading = false;
						});
				} else {
					alert("La fecha de entrega no puede ser antes del presente dia");
				}
			} else {
				alert(
					"Por favor ingresar los campos Tipo de pago, Fecha de entrega y Tiempo Estimados"
				);
			}
		},
		////////////////////////////////////////////////////////////////////////////////////////////////////////
		idVendedor() {
			for (let j = 0; j < this.vendedoress.length; j++) {
				if (this.vendedorModificado) {
					if (
						this.vendedorAsignado.substring(
							0,
							this.vendedorAsignado.length - 5
						) === this.vendedoress[j].name
					) {
						this.vendedorId = this.vendedoress[j].vendedor_id;
						this.user_Receptor = this.vendedoress[j].user_id;
						break;
					}
				} else {
					if (this.vendedorAsignado === this.vendedoress[j].name) {
						this.vendedorId = this.vendedoress[j].vendedor_id;
						this.user_Receptor = this.vendedoress[j].user_id;
						break;
					}
				}
			}
		},
		notificarNuevaOrdenCompraBroadcast() {
			window.axios.post(
				process.env.VUE_APP_BACKEND_URL + "/tipoCambioMensaje",
				{
					message:
						"Orden de compra N°" +
						String(this.compraId.id).padStart(4, "0") +
						" generada.",
					user_receptor: "G-SU",
					compras_id: String(this.compraId.id),
					check: "1"
				}
			);

			window.axios.post(
				process.env.VUE_APP_BACKEND_URL + "/tipoCambioMensaje",
				{
					message:
						"Orden de compra N°" +
						String(this.compraId.id).padStart(4, "0") +
						" asignada.",
					user_receptor: String(this.user_Receptor),
					compras_id: String(this.compraId.id),
					check: "1",
					updated_at: String(this.fechaEntrega)
				}
			);
		},
		async pesosVendedor(id) {
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: require("@/graphql/mutations/completarPesosVendedorCotizacion.gql"),
					variables: {
						id: parseInt(id)
					}
				})
				.then(() => {
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		}
	}
};
</script>
